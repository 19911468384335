import React from "react"

import Layout from "../components/layout"
import Button from "../components/button"

const subMessages = [
  `Mehr gibt's dazu nicht zu sagen.`,
  `Traurig, aber wahr.`,
]
const NotFoundPage = () => {
  const randIdx = Math.floor(Math.random() * subMessages.length)
  const subMessage = subMessages[randIdx];
  return (
    <Layout>
      <h1>Seite nicht gefunden</h1>
      <p>Diese Seite gibt es nicht. {subMessage}</p>
      <p>
        <Button link="/" text="Zurück zur Startseite" primary />
        {` `}
        <Button link="/kontakt/" text="Nachricht schreiben" />
      </p>
    </Layout>
  )
}

export default NotFoundPage
